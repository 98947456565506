$(function(){
  // window.addEventListener("load", () => {
  //   const element = document.querySelector("#new-inquiry");
  //   element.addEventListener("ajax:success", (event) => {
  //     const [_data, _status, xhr] = event.detail;
  //     var message = []
  //     if (_data.status == "404"){
  //       $.each(_data.msg, function(key,value){
  //         err_obj =key.charAt(0).toUpperCase()+key.slice(1)+' '+ value.join(' and ')
  //         message.push(err_obj)
  //       })
  //       console.log(message)
  //     }else{
  //       message.push(_data.msg)
  //     }
  //     Swal.fire({
  //       title: _data.status=="404"? 'Error!' : 'Success',
  //       html: message.join('<br/>'),
  //       icon: _data.status=="404"? 'error' : 'success',
  //       confirmButtonText:  _data.status=="404"? 'Try Again' : "Done"
  //     })
  //     // element.insertAdjacentHTML("beforeend", xhr.responseText);
  //   });
  //   element.addEventListener("ajax:error", () => {
  //     console.log("IN ERROR",_data);
  //     console.log("IN ERROR",_status);
  //     element.insertAdjacentHTML("beforeend", "<p>ERROR</p>");
  //   });
  // });
})